<template>
    <div class="my-2">
        <p class="text-gray-400 font-semibold">🚀 Thanks! Where do we send your prize?</p>
        <div class="mt-2 text-left">
            <div>
                <p class="text-black-500 font-semibold">Email Address*</p>
                <input placeholder="jdoe@gmail.com" v-model="emailAddress" class="mt-1 p-2 border border-gray-300 rounded-lg w-full block shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"/>
            </div>
        </div>
        <div class="mt-2 text-left">
            <div>
                <p class="text-black-500 font-semibold">Cell Phone*</p>
                <input type="tel" name="telephone" pattern="[0-9]*" inputmode="numeric" v-maska data-maska="(###) ###-####" placeholder="(954) 729-5454" v-model="mobilePhone" class="mt-1 p-2 border border-gray-300 rounded-lg w-full block shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"/>
            </div>
        </div>
        <div class="mt-2 text-left">
            <div>
                <p class="text-black-500 font-semibold">Street Address*</p>
                <input placeholder="123 Main St" v-model="streetAddress" class="mt-1 p-2 border border-gray-300 rounded-lg w-full block shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"/>
            </div>
        </div>
        <div class="mt-2">

            <div class="p-2 rounded-sm bg-red-50 border border-red-300 font-semibold" v-if="errorMessage">
                <p class="text-xs text-gray-600">{{ errorMessage }}</p>
            </div>

            <button @click="nextStep" type="button" class="mt-2 inline-block items-center rounded-md border border-transparent bg-indigo-600 w-full px-3 py-2 text-sm font-bold leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Confirm My Address</button>
        </div>
        <div class="mt-2">
            <p class="text-xs text-gray-400">By submitting "Confirm My Address", I provide my express written consent via this webform interaction for a licensed insurance agent associated with Your6400Benefit, Insurance Made Easy, IME, Trusted Healthcare Group, Benefits Made Easy, and BME to contact me at the number I provided, even if the phone number provided is on the National Do Not Call registry, regarding products or services, including ACA, health insurance, prescription drug insurance plans, and other health-related services via live, automated dialing system telephone call, text, or email. I understand this request has been initiated by me at (CURRENT TIME, DATE) and that this is an unscheduled contact request. I understand my telephone company may impose charges on me for these contacts and am not required to enter into this agreement as a condition of any purchase or service. I further understand that this request, initiated by me at (CURRENT TIME, DATE), is my affirmative consent to be contacted which is in compliance with all federal and state telemarketing and Do-Not-Call laws. I may revoke consent to contact at any time. Licensed Sales Agents are not connected with or endorsed by the U.S. government. I agree to the Privacy Policy and Terms and Conditions. Please note this is a solicitation for insurance. No obligation to enroll.</p>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { vMaska } from "maska";
    export default {
        name: 'ConfirmYourAddress',
        directives: { maska: vMaska },
        data() {
            return {
                emailAddress: '',
                mobilePhone: '',
                streetAddress: '',
                errorMessage: ''
            }
        },
        methods: {
            nextStep() {

                this.errorMessage = '';

                if (this.emailAddress === '' || this.mobilePhone === '' || this.streetAddress === '') {
                    this.errorMessage = 'Please fill out all fields';
                    return;
                }

                if(this.emailAddress.length < 6 || this.emailAddress.indexOf('@') === -1 || this.emailAddress.indexOf('.') === -1) {
                    this.errorMessage = 'Please enter a valid email address';
                    return;
                }

                localStorage.setItem('user.third_step', JSON.stringify({
                    emailAddress: this.emailAddress,
                    mobilePhone: this.mobilePhone,
                    streetAddress: this.streetAddress
                }));

                let firstStep = JSON.parse(localStorage.getItem('user.first_step'))
                let secondStep = JSON.parse(localStorage.getItem('user.second_step'))

                let jornaya = document.querySelector("#leadid_token").value;

                axios.post(`https://eo75yyqu1eer9y3.m.pipedream.net`, {
                    jornaya: jornaya,
                    firstName: firstStep.firstName,
                    lastName: firstStep.lastName,
                    zipCode: firstStep.zipCode,
                    dateOfBirth: `${secondStep.dobMonth}/${secondStep.dobDay}/${secondStep.dobYear}`,
                    emailAddress: this.emailAddress,
                    mobilePhone: this.mobilePhone,
                    streetAddress: this.streetAddress,
                    userAgent: window.navigator.userAgent,
                    sub1: window.EF.urlParameter('sub1'),
                    sub2: window.EF.urlParameter('sub2'),
                    sub3: window.EF.urlParameter('sub3'),
                    sub4: window.EF.urlParameter('sub4'),
                    sub5: window.EF.urlParameter('sub5'),
                    affiliate_name: window.EF.urlParameter('affiliate_name'),
                })

                window.EF.conversion({
                    offer_id: 43,
                    transaction_id: window.EF.urlParameter('transaction_id'),
                    event_id: 782,
                });

                window.EF.conversion({
                    offer_id: 43,
                    transaction_id: window.EF.urlParameter('transaction_id')
                });

                window.fbq('track', 'Lead');

                this.$router.push({ path: '/thank-you', query: {...this.$route.query}, })
            }
        }
    }
</script>