<template>
    <div class="my-2">
        <p class="font-extrabold text-gray-900">Enter to win an Apple Watch Series 9!</p>
        <img src="../assets/apple-watch.webp" class="my-1 w-64 block m-auto"/>
        <p>Enter your information below and start our short survey to enter and have a chance at winning.</p>
        <div class="mt-2 grid grid-cols-2 gap-4 text-left">
            <div>
                <p class="text-black-500 font-semibold">First Name*</p>
                <input v-model="firstName" class="mt-1 p-2 border border-gray-300 rounded-lg w-full block shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"/>
            </div>
            <div>
                <p class="text-black-500 font-semibold">Last Name*</p>
                <input v-model="lastName" class="mt-1 p-2 border border-gray-300 rounded-lg w-full block shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"/>
            </div>
        </div>
        <div class="mt-2 text-left">
            <div>
                <p class="text-black-500 font-semibold">Zip Code*</p>
                <input type="tel" name="telephone" pattern="[0-9]*" inputmode="numeric" v-maska data-maska="#####" v-model="zipCode" :class="`mt-1 p-2 border ${zipCode.length == 5 && !zipIsValid ? 'border-red-300 bg-red-300' : 'border-gray-300'} rounded-lg w-full block shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`"/>
            </div>
        </div>
        <div class="mt-2">

            <div class="p-2 rounded-sm bg-red-50 border border-red-300 font-semibold" v-if="errorMessage">
                <p class="text-xs text-gray-600">{{ errorMessage }}</p>
            </div>

            <button @click="nextStep" type="button" class="mt-2 inline-block items-center rounded-md border border-transparent bg-indigo-600 w-full px-3 py-2 text-sm font-bold leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Next Step</button>
        </div>
        <div class="mt-2">
            <p class="text-xs text-gray-400">By clicking "Next Step" you are agreeing to our privacy policy and sweepstake rules.</p>
        </div>
        <div class="mt-2">
            <p class="text-xs text-gray-400">This site is not a part of the Facebook™ website or Facebook™ Inc. Additionally, this site is NOT endorsed by Facebook™ in any way. FACEBOOK™ is a trademark of FACEBOOK™, Inc.</p>
        </div>
    </div>
</template>

<script>
import { vMaska } from "maska"

export default {
    name: 'AppleWatch',
    directives: { maska: vMaska },
    data() {
        return {
            firstName: '',
            lastName: '',
            zipCode: '',
            zipIsValid: true,
            errorMessage: ''
        }
    },
    watch: {
        zipCode: function (val) {
            if (val.length === 5) {
                this.validateZip()
            }
        }
    },
    methods: {
        async validateZip() {
            const response = await fetch(`https://eotp6yepuae7weh.m.pipedream.net/?zip=${this.zipCode}`);
            const data = await response.json();
            if(data.results.hasOwnProperty(this.zipCode)) {
                console.log("Hello world")
                this.zipIsValid = true;
                localStorage.setItem('address.zipCode', this.zipCode);
                localStorage.setItem('address.city', data.results[this.zipCode][0].city);
                localStorage.setItem('address.state', data.results[this.zipCode][0].state_code);
            } else {
                this.zipIsValid = false;
            }
            // this.zipIsValid = data.places.length > 0;
        },
        nextStep() {

            this.errorMessage = '';

            if(this.firstName === '' || this.lastName === '' || this.zipCode === '' || !this.zipIsValid) {
                this.errorMessage = 'Please fill out all fields and make sure your zip code is valid.';
                return;
            }

            localStorage.setItem('user.first_step', JSON.stringify({
                firstName: this.firstName,
                lastName: this.lastName,
                zipCode: this.zipCode
            }));

            window.EF.conversion({
                offer_id: 43,
                transaction_id: window.EF.urlParameter('transaction_id'),
                event_id: 780,
            });

            this.$router.push({ path: '/choose-your-watch', query: {...this.$route.query}, params: { firstName: this.firstName, lastName: this.lastName, zipCode: this.zipCode } })

            // this.$router.push({ path: '/choose-your-age', query: {...this.$route.query}, params: { firstName: this.firstName, lastName: this.lastName, zipCode: this.zipCode } })
        }
    }
}
</script>