<template>
    <div class="my-2">
        <p class="font-extrabold text-gray-900">Enter to win an Apple Watch!</p>
        <img src="../assets/apple-watch.png" class="my-4 w-48 block m-auto"/>
        <p>Enter your information below and start our short survey to enter and have a chance at winning.</p>
        <div class="mt-2 text-left">
            <div>
                <p class="text-black-500 font-semibold">Email Address*</p>
                <input placeholder="jdoe@gmail.com" v-model="emailAddress" class="mt-1 p-2 border border-gray-300 rounded-lg w-full block shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"/>
            </div>
        </div>
        <div class="mt-2">
            <button @click="nextStep" type="button" class="mt-2 inline-block items-center rounded-md border border-transparent bg-indigo-600 w-full px-3 py-2 text-sm font-bold leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Next Step</button>
        </div>
        <div class="mt-2">
            <p class="text-xs text-gray-400">By clicking "Next Step" you are agreeing to our privacy policy and sweepstake rules.</p>
        </div>
        <div class="mt-2">
            <p class="text-xs text-gray-400">This site is not a part of the Facebook™ website or Facebook™ Inc. Additionally, this site is NOT endorsed by Facebook™ in any way. FACEBOOK™ is a trademark of FACEBOOK™, Inc.</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'AppleWatch',
    data() {
        return {
            emailAddress: '',
        }
    },
    methods: {
        async nextStep() {

            // get the facebook click id from search 
            const urlParams = new URLSearchParams(window.location.search);
            const clickId = urlParams.get('fbclid');

            await axios.post(`https://eo9suxn33d2iggr.m.pipedream.net`, {
                email: this.emailAddress,
                fbclid: clickId
            })

            window.fbq('track', 'EmailSubmit')
            if(window.hasOwnProperty('ttq')) {
                window.ttq.track('CompleteRegistration')
            }

            this.$router.push({ path: '/thank-you' })
        }
    }
}
</script>