<template>
    <div class="thank-you">
        <p class="font-semibold text-md">Congratulations! Your Entry Is In!</p>
        <h1 class="mt-1 text-xl font-bold">Thank You! Almost there! 🎉</h1>
        <div class="my-2 bg-gray-50 border border-dashed border-gray-300 text-xs p-2 text-center">
            {{zipCode}} - ENTRY#{{clickId}}
        </div>
        <div class="shadow-xl bg-gray-100 rounded-full h-2.5 mt-8 relative">
            <img src="../assets/apple-watch.png" style="top: -45px;right: -13px;position: absolute;" class="absolute my-4 w-16 block m-auto"/>
            <div class="bg-blue-600 h-2.5 rounded-full" style="width: 80%"></div>
        </div>
        <hr class="my-5"/>
        
        <div class="my-5">
            <p class="mt-8 font-medium text-2xl"><strong>We've Found 2 Relief Programs For {{ zipCode }}</strong></p>

            <div @click="goToOffer('https://www.t83hftrk.com/4MZ2NG/24X9WZ/')" class="mt-5 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 text-left border dotted">
                <img src="../assets/1largerplaybutton.png" class="w-full object-contain"/>
                <div class="p-2">
                    <p>
                        <strong>{{ state }} Residents: $6,400 Subsidy For Health & Relief.</strong>
                        <br/>
                        <span class="text-gray-500">Groceries, Gym, Utilities, Rent Assistance and More!</span>
                    </p>
                    <p>
                        See How <i class="fas fa-arrow-right"></i>
                    </p>
                </div>
            </div>

            <div @click="goToOffer('https://www.t83hftrk.com/4MZ2NG/33B3WH/')" class="mt-5 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 text-left border dotted">
                <img src="../assets/subsidy-debt.jpg" class="w-full object-contain"/>
                <div class="p-2">
                    <p>
                        <strong>Have At Least $10,000 Debt: Benefits For Bills Finds Your Relief & Aid</strong>
                        <br/>
                        <span class="text-gray-500">Inflation help is available for your area.</span>
                    </p>
                    <p>
                        See How <i class="fas fa-arrow-right"></i>
                    </p>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import moment from 'moment';
export default {
    mounted() {
        if(localStorage.hasOwnProperty('user.second_step') !== false) {
            let secondStep = JSON.parse(localStorage.getItem('user.second_step'));
            if((moment().format('YYYY') - secondStep.dobYear) > 64) {
                this.showO65 = true;
            }    
        }

        if(localStorage.hasOwnProperty('age') === false) {
            localStorage.setItem('age', 0);
        }

        let age = localStorage.getItem('age');
        if(age > 64) {
            this.showO65 = true;
        }
    },
    data() {
        return {
            showO65: false,
            zipCode: localStorage.getItem('address.zipCode'),
            state: localStorage.getItem('address.state'),
            clickId: localStorage.getItem('clickId')
        }
    },
    methods: {
        goToOffer(href) {
            window.fbq('track', 'ThankYouPageClick');
            window.location = href;
        }
    }
}
</script>