<template>
    <div class="my-2">
        <p class="font-extrabold text-gray-900">Select Your Preferred Apple Watch Series 9 Color:</p>
        <div class="my-2 grid grid-cols-2 gap-4">
            <div class="shadow-md rounded-md bg-white py-3 px-1" @click="nextStep('midnight')">
                <img src="../assets/MIDNIGHT.jpeg" class="w-56 mx-auto"/>
                <p class="font-bold text-gray-900 text-md">Midnight</p>
            </div>
            <div class="shadow-md rounded-md bg-white py-3 px-1" @click="nextStep('pink')">
                <img src="../assets/PINK.jpeg" class="w-56 mx-auto"/>
                <p class="font-bold text-gray-900 text-md">Pink</p>
            </div>
            <div class="shadow-md rounded-md bg-white py-3 px-1" @click="nextStep('silver')">
                <img src="../assets/SILVER.jpeg" class="w-56 mx-auto"/>
                <p class="font-bold text-gray-900 text-md">Silver</p>
            </div>
            <div class="shadow-md rounded-md bg-white py-3 px-1" @click="nextStep('starlight')">
                <img src="../assets/STARLIGHT.jpeg" class="w-56 mx-auto"/>
                <p class="font-bold text-gray-900 text-md">Starlight</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {TheMask} from 'vue-the-mask'

    export default {
        name: 'ConfirmYourAge',
        components: {
            TheMask
        },
        data() {
            return {
                dobMonth: '',
                dobDay: '',
                dobYear: ''
            }
        },
        methods: {
            nextStep(choice) {
                localStorage.setItem('user.watch_selection', choice);

                window.EF.conversion({
                    offer_id: 43,
                    transaction_id: window.EF.urlParameter('transaction_id'),
                    event_id: 783,
                });

                this.$router.push({ path: '/confirm-your-age', query: {...this.$route.query}, })
            }
        }
    }
</script>