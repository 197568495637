<template>
    <div class="my-2">
        <p class="font-extrabold text-gray-900">Enter to win an Apple Watch!</p>
        <img src="../assets/apple-watch.png" class="my-4 w-48 block m-auto"/>
        <p>Answer the question below and start our short survey to enter and have a chance at winning.</p>
        <div class="mt-2 mb-3 text-center">
            <div>
                <p class="text-black-500 text-xl font-semibold">{{currentQuestion.question}}</p>
                <div class="grid grid-cols-2 space-x-4">
                    <button v-for="choice in currentQuestion.choices" :key="choice.value" @click="chooseAnswer(choice.value)" type="button" class="mt-2 inline-block items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-bold leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">{{choice.label}}</button>
                </div>
            </div>
        </div>
        <div class="mt-2">
            <p class="text-xs text-gray-400">By beginning our survey, you are agreeing to our privacy policy and sweepstake rules.</p>
        </div>
        <div class="mt-2">
            <p class="text-xs text-gray-400">This site is not a part of the Facebook™ website or Facebook™ Inc. Additionally, this site is NOT endorsed by Facebook™ in any way. FACEBOOK™ is a trademark of FACEBOOK™, Inc.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppleWatch',
    data() {
        return {
            questionIndex: 0,
            questions: [
                {
                    question: 'Do you consider yourself a healthy person?',
                    choices: [
                        {
                            label: 'Yes',
                            value: 'yes'
                        },
                        {
                            label: 'No',
                            value: 'no'
                        }
                    ]
                },
                {
                    question: 'If you won the Apple Watch would you connect it to your iPhone?',
                    choices: [
                        {
                            label: 'Yes',
                            value: 'yes'
                        },
                        {
                            label: 'No',
                            value: 'no'
                        }
                    ]
                },
                {
                    question: 'Are you over the age of 65?',
                    choices: [
                        {
                            label: 'Yes',
                            value: 'o65'
                        },
                        {
                            label: 'No',
                            value: 'u65'
                        }
                    ]
                }
            ],
            answer: '',
        }
    },
    computed: {
        currentQuestion() {
            return this.questions[this.questionIndex]
        }
    },
    methods: {
        async chooseAnswer(choice) {
            if(choice == 'o65' || choice == 'u65') {
                // save choice to localStorage
                localStorage.setItem('age', choice == 'o65' ? 65 : 64)

                window.fbq('track', 'AgeSubmit')

                this.$router.push({ path: '/thank-you' })
            } else {
                this.questionIndex++
            }
        }
    }
}
</script>