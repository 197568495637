import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './components/HomePage.vue'
import AppleWatch from './components/AppleWatch.vue'
import ChooseYourWatch from './components/ChooseYourWatch.vue'
import AppleWatchEmailOnly from './components/AppleWatchEmailOnly.vue'
import AppleWatchAgeFilter from './components/AppleWatchAgeFilter.vue'
import ConfirmYourAge from './components/ConfirmYourAge.vue'
import ConfirmYourAddress from './components/ConfirmYourAddress.vue'
import ThankYou from './components/ThankYou.vue'
import App from './App.vue'

const routes = [
    { path: '/', component: HomePage },
    { path: '/choose-your-watch', component: ChooseYourWatch},
    { path: '/apple-watch', component: AppleWatch },
    { path: '/apple-watch-email', component: AppleWatchEmailOnly },
    { path: '/apple-watch-click', component: AppleWatchAgeFilter },
    { path: '/confirm-your-age', component: ConfirmYourAge },
    { path: '/confirm-your-address', component: ConfirmYourAddress },
    { path: '/thank-you', component: ThankYou },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

createApp(App).use(router).mount('#app');