<template>
  <div style="position: absolute; width: 100%; height: 100%; left: 0; top: 0; background: rgb(255,97,0);
background: linear-gradient(180deg, rgba(255,97,0,1) 0%, rgba(239,0,255,1) 100%);">
    <div class="md:w-96 md:m-auto md:mt-5 m-2 p-5 bg-white shadow overflow-hidden rounded-lg">
      <p class="text-center font-bold">Brand Giveaways</p>
      <!-- <div class="table text-center w-100 m-auto"><div class="table-cell p-2"><span class="text-black-500 font-bold text-2xl">554</span><br><span class="text-gray-400">Entries</span></div><div class="table-cell p-2"><div class="text-black-500 font-bold text-2xl"> 30 </div><span class="text-gray-400">Days Left</span></div></div> -->
      <div class="mt-2 flex justify-center items-center space-x-4">
        <div class="text-center">
          <p class="font-bold text-black-500 text-2xl">{{ entries }}</p>
          <p>Entries</p>
        </div>
        <div class="text-center">
          <p class="font-bold text-black-500 text-2xl">{{ daysLeft }}</p>
          <p>Days Left</p>
        </div>
      </div>
      <div class="text-center my-2">
        <p>Entry #{{ clickId }}</p>
      </div>
      <router-view></router-view>    
      <div calss="h-10"></div>
      <!-- <div class="text-xs text-gray-400">The testimonials on our videos are from paid actors. If you need help, email support@mybrandgiveaways.com or call *877-692-4441. Our website matches you with companies for information purposes. Brand Giveaways, a referral service, might share your details or direct your call to partner companies. We aim for top-notch service and might contact you about your order, feedback, or assistance with your plans or services, even if you're on the do-not-call list. You can tell our team anytime if you don't want more calls. Both calls and texts may use automated systems, and your carrier might charge you. To stop texts, reply with "STOP" anytime.</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      entries: 0,
      daysLeft: 0,
      clickId: ""
    }
  },
  methods: {
    setEntriesAndDaysLeft() {
      // generate random entries and days left
      let entries = Math.floor(Math.random() * 1000);
      let daysLeft = Math.floor(Math.random() * 30);
      let clickId = window.EF.urlParameter('transaction_id').substring(0, 8);

      // set entries and days left
      localStorage.setItem('entries', entries);
      localStorage.setItem('daysLeft', daysLeft);
      localStorage.setItem('clickId', clickId);

      // set data
      this.entries = entries;
      this.daysLeft = daysLeft;
      this.clickId = clickId;
    }
  },
  mounted() {
    if (localStorage.getItem('entries') === null) {
      this.setEntriesAndDaysLeft();
    } else {
      this.entries = localStorage.getItem('entries');
      this.daysLeft = localStorage.getItem('daysLeft');
      this.clickId = localStorage.getItem('clickId');
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
