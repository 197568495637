<template>
    <div>
        <div class="my-5">

            <!-- COVID offer -->

            <div v-if="showO65" @click="goToOffer('https://www.click3r.com/25B3FS5/R5PTZD/?source_id=mybrand&sub1=home-page')" class="hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 text-left border dotted p-5 rounded-xl flex">
                <img src="https://www.medicare-411.com/free-covid-tests/assets/img/LOGO.png" class="w-24 object-contain"/>
                <div class="ml-5">
                    <p>
                        <strong>Medicare now covers COVID-19 tests, at no cost to you.</strong>
                        <br/>
                        <span class="text-gray-500">Click here to place your $0 order! Ending soon! Enter now!</span>
                    </p>
                    <p>
                        Go There <i class="fas fa-arrow-right"></i>
                    </p>
                </div>
            </div>

            <div @click="goToOffer('https://www.click3r.com/25B3FS5/R2Z4H6/?source_id=mybrand&sub1=home-page')" class="mt-2 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 text-left border dotted p-5 rounded-xl flex">
                <img src="https://cdn.sweepstakesalerts.com/images/2020/10/05/sa-win5k-money-wings.png" class="w-24 object-contain"/>
                <div class="ml-5">
                    <p>
                        <strong>Enter to win $5,000!</strong>
                        <br/>
                        <span class="text-gray-500">Prize draw is ending soon! Enter now!</span>
                    </p>
                    <p>
                        Go There <i class="fas fa-arrow-right"></i>
                    </p>
                </div>
            </div>

            <div @click="goToOffer('https://www.click3r.com/25B3FS5/R2Z4H6/?source_id=mybrand&sub1=home-page')" class="mt-3 hover:bg-gray-100 active:bg-gray-200 hover:cursor-pointer text-left border dotted p-5 rounded-xl flex">
                <img src="https://cdn.prizegrab.com/media/img/prizes/829ec01e-1cc6-49be-9759-c1abfc8f03e4.png" class="w-24 object-contain"/>
                <div class="ml-5">
                    <p>
                        <strong>Do you love Ikea?</strong>
                        <br/>
                        <span class="text-gray-500">Win a $250 Ikea Gift Card. No purchase necessary. Enter now!</span>
                    </p>
                    <p>
                        Go There <i class="fas fa-arrow-right"></i>
                    </p>
                </div>
            </div>

            <div @click="goToOffer('https://www.click3r.com/25B3FS5/QZ6C1Z/?source_id=mybrand&sub1=home-page')" class="mt-3 hover:bg-gray-100 active:bg-gray-200 hover:cursor-pointer text-left border dotted p-5 rounded-xl flex">
                <img src="https://www.wegmans.com/wp-content/uploads/5888989-grocery-delivery-curbside5.jpg" class="w-24 object-contain"/>
                <div class="ml-5">
                    <p>
                        <strong>$500 Grocery Gift Card</strong>
                        <br/>
                        <span class="text-gray-500">This is a local government program! Enter now!</span>
                    </p>
                    <p>
                        Go There <i class="fas fa-arrow-right"></i>
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    mounted() {
        let secondStep = JSON.parse(localStorage.getItem('user.second_step'));
        if((moment().format('YYYY') - secondStep.dobYear) > 64) {
            this.showO65 = true;
        }
    },
    data() {
        return {
            showO65: false
        }
    },
    methods: {
        goToOffer(href) {
            window.fbq('track', 'ThankYouPageClick');
            window.location = href;
        }
    }
}
</script>
