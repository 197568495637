<template>
    <div class="my-2">
        <p class="text-gray-400 font-semibold">🙋‍♀️🙋‍♂️ We collect date of birth to validate our winners!</p>
        <div class="my-2 grid grid-cols-3 gap-4">
            <div>
                <p class="text-black-500 font-semibold">Month</p>
                <input type="tel" name="telephone" pattern="[0-9]*" inputmode="numeric" v-maska data-maska="##" placeholder="MM" v-model="dobMonth" class="mt-1 p-2 border border-gray-300 rounded-lg w-full block shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"/>
            </div>
            <div>
                <p class="text-black-500 font-semibold">Day</p>
                <input type="tel" name="telephone" pattern="[0-9]*" inputmode="numeric" v-maska data-maska="##" placeholder="DD" v-model="dobDay" class="mt-1 p-2 border border-gray-300 rounded-lg w-full block shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"/>
            </div>
            <div>
                <p class="text-black-500 font-semibold">Year</p>
                <input type="tel" name="telephone" pattern="[0-9]*" inputmode="numeric" v-maska data-maska="####" placeholder="YYYY" v-model="dobYear" class="mt-1 p-2 border border-gray-300 rounded-lg w-full block shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"/>
            </div>
        </div>
        <div class="mt-2">

            <div class="p-2 rounded-sm bg-red-50 border border-red-300 font-semibold" v-if="dobError">
                <p class="text-xs text-gray-600">{{ dobError }}</p>
            </div>

            <button @click="nextStep" type="button" class="mt-2 inline-block items-center rounded-md border border-transparent bg-indigo-600 w-full px-3 py-2 text-sm font-bold leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Confirm My Age</button>
        </div>
    </div>
</template>

<script>
    import { vMaska } from "maska"

    export default {
        name: 'ConfirmYourAge',
        directives: { maska: vMaska },
        data() {
            return {
                dobMonth: '',
                dobDay: '',
                dobYear: '',
                dobError: ''
            }
        },
        methods: {
            nextStep() {

                this.dobError = '';

                if (this.dobMonth.length !== 2 || this.dobDay.length !== 2 || this.dobYear.length !== 4) {
                    this.dobError = 'Please enter a valid date of birth';
                    return;
                }

                if(this.dobMonth < 1 || this.dobMonth > 12) {
                    this.dobError = 'Please enter a valid month';
                    return;
                }

                if(this.dobDay < 1 || this.dobDay > 31) {
                    this.dobError = 'Please enter a valid day';
                    return;
                }

                if(this.dobYear < 1900 || this.dobYear > new Date().getFullYear()) {
                    this.dobError = 'Please enter a valid year';
                    return;
                }

                localStorage.setItem('user.second_step', JSON.stringify({
                    dobMonth: this.dobMonth,
                    dobDay: this.dobDay,
                    dobYear: this.dobYear
                }));

                window.EF.conversion({
                    offer_id: 43,
                    transaction_id: window.EF.urlParameter('transaction_id'),
                    event_id: 781,
                });

                this.$router.push({ path: '/confirm-your-address', query: {...this.$route.query}, })
            }
        }
    }
</script>